import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { NavComponent } from './nav/nav.component';
import { TeamComponent } from './team/team.component';
import { FooterComponent } from './footer/footer.component';
import { BlogComponent } from './blog/blog.component';
import { GalleryComponent } from './gallery/gallery.component';
import { OurAuthorComponent } from './our-author/our-author.component';
import { EnrollNowComponent } from './enroll-now/enroll-now.component';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { CoursesGridComponent } from './courses/courses-grid/courses-grid.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { EbooksComponent } from './ebooks/ebooks.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ThankyouEbookComponent } from './thankyou-ebook/thankyou-ebook.component';
import { ProductComponent } from './product/product.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    HomeComponent,
    NavComponent,
    TeamComponent,
    FooterComponent,
    BlogComponent,
    GalleryComponent,
    OurAuthorComponent,
    EnrollNowComponent,
    CoursesGridComponent,
    TermConditionComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent,
    ThankyouComponent,
    EbooksComponent,
    CheckoutComponent,
    ThankyouEbookComponent,
    ProductComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    RouterModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
