import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyou-ebook',
  templateUrl: './thankyou-ebook.component.html',
  styleUrls: ['./thankyou-ebook.component.scss']
})
export class ThankyouEbookComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.onload = function() {
      document.getElementById('downloadebook').click();
  }
  }

  downloadMyFile() {
    var link = document.createElement('a');
    link.href = '/assets/images/ebooks/Devanagari-Book.pdf';
    link.download ='Devanagari Calligraphy (Basics).pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

}
