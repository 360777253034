import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../service/common.service";
declare var $:any
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {


  public httpOptions:any
public name:any
public email:any
public Subject:any
public msg:any


  constructor(public router:Router,private commonService: CommonService,private http: HttpClient) { 

    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        })
    };
  }

  ngOnInit() {
   
   
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  
  }
  contactus(){
    this.name = (<HTMLInputElement>document.getElementById("name")).value;
    this.email = (<HTMLInputElement>document.getElementById("email")).value;
    this.Subject = (<HTMLInputElement>document.getElementById("subject")).value;
    this.msg = (<HTMLInputElement>document.getElementById("message-contact")).value;


if(this.name && this.email && this.msg != undefined && this.name  && this.email && this.msg != "" ){
    this.http.post(this.commonService.domain + "contact",
    {
      name:this.name,
      email: this.email,
      subject:this.Subject,
      message: this.msg
    },
     this.httpOptions)
    .subscribe(res => {
      
      console.log(res["success"]);
      alert(res["success"]);  
    },err =>{
      console.log(err.error.success);
    });

  }
  else{
  alert("pls fill the mandatory required");  
  }
  }


}
