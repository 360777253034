import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor() { } 

  ngOnInit() {

    $(document).ready(function(){
      $(".btn-menu").click(function(){
      $(".btn-menu").removeClass("active");
        
        $("#mainnav-mobi").toggle();
        
      });
    });
  }

}
