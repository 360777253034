import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../service/common.service";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  public httpOptions: any;

  public firstname:any;
  public lastname:any;
  public city:any;
  public area:any;
  public fulladress:any;

  public photoid:any;

  public idnumber:any;
  public qualification:any;
  public workexperience:any;
  public remark:any;



  constructor(
private http: HttpClient,
    private router: Router,
    private commonService: CommonService,


  ) { 

    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      
      })
    };
  }

  ngOnInit() {
   

  }

  submit(){
    this.firstname = (<HTMLInputElement> document.getElementById("firstname")).value;
    this.lastname = (<HTMLInputElement> document.getElementById("lastname")).value;
    this.city = (<HTMLInputElement> document.getElementById("city")).value;
    this.area = (<HTMLInputElement> document.getElementById("area")).value;
    this.fulladress = (<HTMLInputElement> document.getElementById("fulladdress")).value;
    this.photoid = (<HTMLInputElement> document.getElementById("photoid")).value;
    this.idnumber = (<HTMLInputElement> document.getElementById("idnumber")).value;
    this.qualification = (<HTMLInputElement> document.getElementById("qualifications")).value;
    this.workexperience = (<HTMLInputElement> document.getElementById("workexperience")).value;
    this.remark = (<HTMLInputElement> document.getElementById("remarks")).value;



   if( this.firstname && this.lastname && this.city && this.area && this.fulladress && this.photoid && this.idnumber && this.qualification 
     && this.workexperience && this.remark != undefined 
    && this.firstname && this.lastname && this.city && this.area && this.fulladress && this.photoid && this.idnumber && this.qualification 
    && this.workexperience && this.remark != ''){ 
this.http.post(this.commonService.domain+"franchise",
{
  
  "first_name": this.firstname,
  "last_name": this.lastname,
  "city": this.city,
  "area": this.area,
  "full_address": this.fulladress,
  "photo_id": this.photoid,
  "id_number": this.idnumber,
  "qualifications": this.qualification,
  "work_experience": this.workexperience,
  "remarks": this.remark,
  

},this.httpOptions).subscribe(res=>{
console.log(res["message"]);
alert("Registered successfully ");
},err=>{
  console.log(err.error.success);

});

}
else{
  alert("pls enter the required filled *");
}
    }

}
