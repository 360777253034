import { Injectable } from '@angular/core';
import idb from 'idb';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _dbPromise;
  data: any;

  constructor() { }

  connectToIDB() {
    this._dbPromise = idb.open('calliGraphy_made_easy', 1, UpgradeDB => {
      if (!UpgradeDB.objectStoreNames.contains('local_storage')) {
        UpgradeDB.createObjectStore('local_storage', { keyPath: 'id', autoIncrement: true });
      }
    });
  }

  deleteItems(target: string, data: string) {
    this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      const store = tx.objectStore(target).delete(data);
    });
  }

  getAllData(target: string) {
    return this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readonly');
      const store = tx.objectStore(target);
      return store.getAll();
    });
  }

  check_add_storageData(arrayData, col: string) {
    this.connectToIDB();
    var check_data = false;
    this.getAllData('local_storage').then(res => {
      if (res.length == 0) {
        this.addItems('local_storage', arrayData);
      } else {
        res.forEach((element, index) => {
          if (element[col] != undefined) {
            check_data = true;
          }
        });

        if (!check_data) {
          this.addItems('local_storage', arrayData);
        }
      }
    });
    return true;
  }

  addItems(target: string, data: any) {
    this._dbPromise.then((db: any) => {
      const tx = db.transaction(target, 'readwrite');
      tx.objectStore(target).put(data);
    });
  }

  delete_storageData(col) {
    this.getAllData('local_storage').then(res => {
      if (res.length == 0) {
        console.log(res);
      } else {
        res.forEach((element, index) => {
          //console.log(element[col]);
          if (element[col] != undefined) {
            //console.log(col, '->', element[col]);
            this.deleteItems('local_storage', element['id']);
          }
        });
      }
    });
    return true
  }
  
  get_storageData() {
    this.data = null;
    var chk = this.getAllData('local_storage');
    return chk;
  }

  updateItems(arrayData, col: string) {
    var del = this.delete_storageData(col);
    var ngThis = this;
    var interval = setInterval(function () {
      if (del) {
        ngThis.check_add_storageData(arrayData, col);
        clearInterval(interval);
      }
    }, 1000)
  }

  return_Data() {
    this.data;
    return this.data;
  }
}
