import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var Razorpay: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  addFrom: any;
  Fullname: string;
  firstname: string;
  lastname: string;
  useriddefault: any;
  Emailid: any;
  mobilenumber: any;
  Zip: any;
  HouseNo: any;
  Street: any;
  Area: any;
  City: any;
  State: any;
  apiservices: any;
  constantsService: any;
  guestid: any;
  forage: any;
  total: any;
  productid: any;
  Message: any;
  orderss: any;

  constructor(public FormBuilder: FormBuilder, private toast: ToastrService) {
    this.addFrom = this.FormBuilder.group({
      FName: new FormControl('', [Validators.required]),
      LName: new FormControl('', [Validators.required]),
      Email: new FormControl('', Validators.compose([Validators.email, Validators.required])),
      Phone: new FormControl('', Validators.compose([Validators.minLength(8), Validators.maxLength(11)])),

    })
  }

  ngOnInit() {
  }

  orderNpayment() {
    this.total = 300;
    this.razorpay();

    // console.log('ss', this.Guestdetail)
    // this.guestdetailss()
    // if (this.Guestdetail ) {
    //   this.razorpay();
    // } else {
    //   this.guestEntry()
    // }

  }
  Guestdetail(arg0: string, Guestdetail: any) {
    throw new Error('Method not implemented.');
  }
  guestdetailss() {
    throw new Error('Method not implemented.');
  }

  guestEntry() {
    this.Fullname = this.firstname + " " + this.lastname;
    var data = {
      "deviceIdentifier": this.useriddefault,
      "fullName": this.Fullname,
      "email": this.Emailid,
      "phoneNumber": this.mobilenumber,
    }
    console.log(data);
    this.razorpay();
    // this.apiservices.postRequest(this.constantsService.GUEST, data).then(res => {
    //   this.guestid = res['guestId'];
    //   if (res['guestId']) {
    //     console.log(res);
    //     this.forage.updateItem('guestid', this.guestid)
    //     this.forage.updateItem('guestdetail', data).then(res => {
    //      this.forage.getItem('guestdetail').then(fetch=>{
    //       this.Guestdetail = fetch;
    //       this.razorpay();
    //      })

    //     })
    //   }
    //   // this.guestid = res['guestId'];
    //   console.log(res);
    // }).catch(err => {
    //   console.log(err);
    //   this.toast.error(err['message']);
    // })
    //   console.log("rids");
  }

  razorpay() {
    let THIS = this;

    // console.log(this.Guestdetail.fullName,'sid')
    let address = this.HouseNo + ", " + this.Street + ", " + this.Area + ", " + this.City + "-" + this.Zip + ", " + this.State + ".";
    var options = {
      key: "rzp_live_bdq9V4iT7npWnL",
      // key: "rzp_test_y4n4eXdJSJDeAE",
      amount: this.total + "00",
      currency: "INR",
      name: this.firstname + ' ' + this.lastname,
      email: this.Emailid,
      description: "Calligraphy Made Easy",
      image: "/assets/images/logo/site_logo.jpg",
      "payment_capture": 1,
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature)
        // THIS.order(response.razorpay_payment_id)
        THIS.tk()
      },
      prefill: {
        name: this.Fullname, // pass customer name
        email: this.Emailid, // customer email
        contact: this.mobilenumber //customer phone no.
      },
      notes: {
        address: address //customer address
      },
      theme: {
        color: "#ffaa30" // screen color
      }
    };
    var rzp1 = new Razorpay(options);
    rzp1.on('payment.failed', function (response) {
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata);
      this.toast.error(response.error.reason);
    });
    rzp1.open();
  }


  order(id) {
    this.forage.getItem('guestid').then(res => {
      console.log(this.guestid)
      var data = {
        "orderTotalValue": this.total,
        "razorpayID": id,
        "guestId": res,
        "shopId": this.productid,
        "message": this.Message
      }
      this.apiservices.postRequest(this.constantsService.ORDER, data).then(res => {
        if (res) {
          console.log(res);
          this.orderss = res['orderId'];
          this.forage.updateItem('orderid', this.orderss);
          this.tk();

        }


      }).catch(err => {
        console.log(err);
        this.toast.error(err['message']);
      })
    })


  }

  tk() {
    window.location.href = '/thanks';
  }

}
