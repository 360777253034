import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../service/common.service";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public httpOptions:any
  public name:any
  public email:any
  public Subject:any
  public msg:any
  constructor(public router:Router,private commonService: CommonService,private http: HttpClient) { }

  ngOnInit() {
  }

  quick_contact(){
    this.name = (<HTMLInputElement>document.getElementById("uname-contact")).value;
    this.email = (<HTMLInputElement>document.getElementById("mobile-contact")).value;
    this.Subject = (<HTMLInputElement>document.getElementById("message-contact")).value;
    this.msg = (<HTMLInputElement>document.getElementById("message-contact")).value;


  if(this.name && this.email && this.msg != undefined && this.name  && this.email && this.msg != "" ){
    this.http.post(this.commonService.domain + "contact",
    {
      name:this.name,
      email: this.email,
      subject:this.Subject,
      message: this.msg
    },
     this.httpOptions)
    .subscribe(res => {
      
      console.log(res["success"]);
      alert(res["success"]);  
    },err =>{
      console.log(err.error.success);
    });

  }
  else{
  alert("pls fill the mandatory required");  
  }
}
}
