import { WindowrefService } from "./../service/windowref.service";

import { Router, NavigationEnd } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CommonService } from "../service/common.service";
import { StorageService } from "../service/storage.service";
import { CommonFunctions } from "../common.function";
import { FormControl, FormGroup } from "@angular/forms";

import { empty, from } from "rxjs";
import { and } from "@angular/router/src/utils/collection";
import { isNull } from "util";
import { Alert } from 'selenium-webdriver';

declare var $: any;
declare var Razorpay: any;
declare var date: any;
@Component({
  selector: "app-enroll-now",
  templateUrl: "./enroll-now.component.html",
  styleUrls: ["./enroll-now.component.scss"]
})
export class EnrollNowComponent implements OnInit {
  public getuserid: any;

  public getcityofarea: any;
  public getcity: any;
  public getarea: any;
  public httpOptions: any;
  public getTotalamount: any;
  // enroll_form1 varaible
  public getcourse_name: any;
  public getcourse_name_show: any;
  public getcourse_centre: any;

  public course_city: any;
  public course_area: any;
  public course_centre: any;
  public course_Total: any;
  //end enroll_form varaible

  // enroll_form2 varaible
  public firstname: any;
  public dob: any;
  public registeras: any;
  public registername: any;
  public pass: any;
  public Mobile: any;
  public Email: any;

  public displayparentname = false;
  //end enroll_form varaible

  // enroll_form3 varaible
  public Address1: any;
  public Address2: any;
  public Address3: any;
  public User_City: any;
  public User_State: any;
  public Post_Code: any;
  public User_Country: any;

  //end enroll_form varaible

  public getradiovalue: any;

  public getdata: any;
  public razorpay_payment_id: String;

  public razorpaystore: any
  constructor(

    private windowref: WindowrefService,
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private storageService: StorageService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImUyZmEzYzRlODc0YTAxYjNkYzljODJkNGQxOGJhMWM4MmE2NWM2MDZiMGVhM2MwNTAyYTZjYWFiNzRmMGM1NjVhZWNhMDM1MDc2NjFlZjIzIn0.eyJhdWQiOiIzIiwianRpIjoiZTJmYTNjNGU4NzRhMDFiM2RjOWM4MmQ0ZDE4YmExYzgyYTY1YzYwNmIwZWEzYzA1MDJhNmNhYWI3NGYwYzU2NWFlY2EwMzUwNzY2MWVmMjMiLCJpYXQiOjE1NjY1NTczMzMsIm5iZiI6MTU2NjU1NzMzMywiZXhwIjoxNTk4MTc5NzMzLCJzdWIiOiIyNyIsInNjb3BlcyI6W119.eWnG-nJ7LqY03AKWzMdsT8hscKvFEcsI-gXDaPVr2mgE6cCcSKG7dh-iN79yL_FelTyZCIi_dP2a5yLX40hqZVyI0hb-vc85FgPofO9qvs-txadpP9ALsV4K1HFRRMSm8nVSiupXpwCGIJTJkk4jqIQJwJkm2IsfogFebTHTYVeZRYy0YpzQtpTks9yE99ZDtxkeZrP2THP7UNqcM8TNlf5jnhgr8P9M-G6O52xeL8rv-A_G4n-_lpOE3E6P5ovKcFckqhfSn2FFHo8sRAUR6r_OXjOUM6yeQUo71Wvregcj-99VhhkEOOD8FSsgIbHtWa4CnHMdebhvJaloRNx750A9oFPcFn4SXiOsh45MADXL_TmqBJwLckIGWSVQBqIyvB7i8ac9qR6m-1tvqTMRRjl-8IFTeWbUEunJHa76-iLrTXOgAMW7K998Hn2q7OyOOhXhbKMYKow9evtFZx31n8qcm6vaSEgdDNwP02yR4ozv6CPG6IqV2WL-XAuYSjJ-ShkVTBJ-_gmQqjf-BxyoTYaDMi3o1yWCMZK68MyUjLgoEkKTVo437--of5SgVqlOTY4afRLVUVp_Um0KidXVpRzcZ8pVPKymokX_qOfmyIuvXYCBFpFa1oolWONrY03sNavbFk4HznJ_7q8PLuT4XRk9YDav3e_3gaviVfolp3I"
      })
    };
  }

  ngOnInit() {
    this.Address2=" ";
    this.Address3=" ";
    var date = new Date();
    console.log(date.toDateString());


    if (this.commonService.selectedCourse != undefined) {
      console.log(this.commonService.selectedCourse);

      if (this.commonService.selectedCourse == "upperIntermediate") {
        this.getcourse_name = "basic";
        this.getcourse_name_show = "upperIntermediate";
      } else if (this.commonService.selectedCourse == "intermediate") {
        this.getcourse_name = "basic";
        this.getcourse_name_show = "intermediate";
      } else if (this.commonService.selectedCourse == "basic") {
        this.getcourse_name = "basic";
        this.getcourse_name_show = "basic";
      } else if (this.commonService.selectedCourse == "advanceOne") {
        this.getcourse_name_show = "advance I";
        this.getcourse_name = "advance";
      } else if (this.commonService.selectedCourse == "advanceTwo") {
        this.getcourse_name = "advance";
        this.getcourse_name_show = "advance II";
      } else if (this.commonService.selectedCourse == "advanceThree") {
        this.getcourse_name = "advance";
        this.getcourse_name_show = "advance III";
      }

      //this.getcourse_name = this.commonService.selectedCourse;
      $("#" + this.commonService.selectedCourse).prop("checked", true);
    }

    /* this.storageService.get_storageData().then((res) => {
      if (CommonFunctions.findKeyIndex(res, 'selectedcheckbox') != undefined) {
        this.commonService.selectedCourse = res[CommonFunctions.findKeyIndex(res, 'selectedcheckbox')]['selectedcheckbox'];
        console.log(this.commonService.selectedCourse);
        $("#" + this.commonService.selectedCourse).prop("checked", true);
      }
    }); */

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    //cme api  integration

    // this.http
    //   .get(this.commonService.domain + "city", this.httpOptions)
    //   .subscribe(res => {
    //     this.getcity = res["city"];
    //   });
    this.http
      .get(this.commonService.domain + "Citys", this.httpOptions)
      .subscribe(res => {
        console.log(res)
       this.getcity = res["City"];
      });

    this.http
      .get(this.commonService.domain + "mumbai_city", this.httpOptions)
      .subscribe(res => {
        this.getcityofarea = res["mumbai_city"];
      });

    // this.http
    // .get(this.commonService.domain + "user", this.httpOptions)
    // .subscribe(res => {
    //   this.getuserid = res["user"];

    // });

    //end cme api  integration

    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {


          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }

  getvalueradio(event) {
    this.getcourse_name_show = event;
    console.log(event);
    if (event == "upperIntermediate") {
      this.getcourse_name = "basic";

    } else if (event == "intermediate") {
      this.getcourse_name = "basic";
    } else if (event == "basic") {
      this.getcourse_name = "basic";
    } else if (event == "advance I") {
      this.getcourse_name = "advance";
    } else if (event == "advance II") {
      this.getcourse_name = "advance";
    } else if (event == "advance III") {
      this.getcourse_name = "advance";
    }


  }

  getvalueCentre(getcenters) {
    this.getcourse_centre = getcenters;
    console.log(this.getcourse_centre);
  }

  getcourse_city(getcityname) {
    var getname = getcityname.target.value;
    console.log(getcityname.target.value);
    this.course_city = getname;
    if (getname == "Mumbai") {
      this.http
        .get(this.commonService.domain + "mumbai_city", this.httpOptions)
        .subscribe(res => {
          this.getcityofarea = res["mumbai_city"];
          console.log(res["mumbai_city"][0].area_name);
          this.course_area = res["mumbai_city"][0].area_name;
          document.getElementById("show_course_area").innerHTML = this.course_area;
        });
    } else if (getname == "Hyderabad") {
      this.http
        .get(this.commonService.domain + "Hyderabad_city", this.httpOptions)
        .subscribe(res => {
          this.getcityofarea = res["hyderabad_city"];
          this.course_area = res["hyderabad_city"][0].area_name;
          document.getElementById("show_course_area").innerHTML = this.course_area;
          
        });
    } else if (getname == "Pune") {
      this.http
        .get(this.commonService.domain + "pune_city", this.httpOptions)
        .subscribe(res => {
         
          this.getcityofarea = res["pune_city"];
          this.course_area = res["pune_city"][0].area_name;
          document.getElementById("show_course_area").innerHTML = this.course_area;
        });
    }
    else if (getname == "Chennai") {
      this.http
        .get(this.commonService.domain + "chennai_city", this.httpOptions)
        .subscribe(res => {
          // console.log(res);
          this.getcityofarea = res["chennai_city"];
          this.course_area = res["chennai_city"][0].area_name;
          document.getElementById("show_course_area").innerHTML = this.course_area;
        });
    }
    
    document.getElementById("show_location").innerHTML = this.course_city;
   
  }

  showdata() {
    this.course_city = (<HTMLInputElement>document.getElementById("city")).value;

    // if (this.course_city == "Mumbai") {
    //   this.http
    //     .get(this.commonService.domain + "mumbai_city", this.httpOptions)
    //     .subscribe(res => {
    //       this.getcityofarea = res["mumbai_city"];
    //       console.log(res["mumbai_city"][0].area_name);
    //       this.course_area = res["mumbai_city"][0].area_name;
    //       document.getElementById("show_course_area").innerHTML = this.course_area;
    //     });
    // } else if (this.course_city == "Hyderabad") {
    //   this.http
    //     .get(this.commonService.domain + "Hyderabad_city", this.httpOptions)
    //     .subscribe(res => {
    //       this.getcityofarea = res["hyderabad_city"];
    //       this.course_area = res["hyderabad_city"][0].area_name;
    //       document.getElementById("show_course_area").innerHTML = this.course_area;
          
    //     });
    // } else if (this.course_city == "Pune") {
    //   this.http
    //     .get(this.commonService.domain + "pune_city", this.httpOptions)
    //     .subscribe(res => {
         
    //       this.getcityofarea = res["pune_city"];
    //       this.course_area = res["pune_city"][0].area_name;
    //       document.getElementById("show_course_area").innerHTML = this.course_area;
    //     });
    // }
    // else if (this.course_city == "Chennai") {
    //   this.http
    //     .get(this.commonService.domain + "chennai_city", this.httpOptions)
    //     .subscribe(res => {
    //       // console.log(res);
    //       this.getcityofarea = res["chennai_city"];
    //       this.course_area = res["chennai_city"][0].area_name;
    //       document.getElementById("show_course_area").innerHTML = this.course_area;
    //     });
    // }




    this.course_area = (<HTMLInputElement> document.getElementById("area")).value;

    console.log(this.course_area);
    document.getElementById("show_location").innerHTML = this.course_city;
    document.getElementById("show_course_area").innerHTML = this.course_area;

    // console.log(this.course_city);

    //  register as parent
    this.registeras = (<HTMLInputElement>document.getElementById("RegisteredAs")).value;
    console.log(this.registeras);
    // console.log(this.registeras);
    if (this.registeras == "Parent") {
      this.registername = (<HTMLInputElement>document.getElementById("ParentName"));
      this.displayparentname = true;

    } else {
      this.displayparentname = false;
    }
    //end  register as parent

    this.firstname = (<HTMLInputElement>(
      document.getElementById("firstname")
    )).value;
    this.Mobile = (<HTMLInputElement>document.getElementById("Mobile")).value;
    this.Email = (<HTMLInputElement>document.getElementById("Email")).value;

    document.getElementById("show_course_name").innerHTML = this.getcourse_name_show;

    document.getElementById("show_course_centre").innerHTML = this.getcourse_centre;

    document.getElementById("show_name").innerHTML = this.firstname;
    document.getElementById("show_Mobile_No").innerHTML = this.Mobile;
    document.getElementById("show_Email_Id").innerHTML = this.Email;

    //console.log(this.course_city,this.course_area,this.getcourse_name,this.getcourse_centre);
    if (this.getcourse_name && this.course_area && this.getcourse_centre != undefined) {
    
    var THIS = this;
      setTimeout(function(){ THIS.calculateapi(); }, 1000);
      
    }

  }

  calculateapi() {
    console.log(this.course_city, this.course_area);

    this.http
      .post(this.commonService.domain + "area",
        {
          b: this.getcourse_name + "_" + this.getcourse_centre,
          city: this.course_city,
          area_name: this.course_area
        },
        this.httpOptions
      )
      .subscribe(res => {
        console.log(res);

        this.getdata = res[0][this.getcourse_name + "_" + this.getcourse_centre];
        console.log(this.getdata);
        document.getElementById("show_course_price").innerHTML = this.getdata;
        this.getTotalamount = this.getdata;

        // console.log(this.getdata[0]['basic_Home']);
        //       this.getdata = res["success"];
        // console.log(this.getdata);
      });

  }

  // insert the enroll record
  place_order() {
    // form1 data

    // end form 1 data

    // start for 2 data
    // this.firstname = (<HTMLInputElement>document.getElementById("firstname")).value;
    this.dob = (<HTMLInputElement>document.getElementById("dob")).value;

    //end form2 data

    // form2 data

    this.Address1 = (<HTMLInputElement>(
      document.getElementById("Address1")
    )).value;
    this.Address2 = (<HTMLInputElement>(
      document.getElementById("Address2")
    )).value;
    this.Address3 = (<HTMLInputElement>(
      document.getElementById("Address3")
    )).value;

    this.User_City = (<HTMLInputElement>(
      document.getElementById("User_City")
    )).value;
    this.User_State = (<HTMLInputElement>(
      document.getElementById("User_State")
    )).value;
    this.Post_Code = (<HTMLInputElement>(
      document.getElementById("Post_Code")
    )).value;
    this.User_Country = (<HTMLInputElement>(
      document.getElementById("User_Country")
    )).value;
    //end form2 data

    // post function to fire query to api
    // course details
    // api for razorpay start

    console.log(this.Mobile);
    if (this.firstname && this.Mobile && this.Email && this.Address1 && this.Address2 && this.Address3
      && this.User_City && this.User_State && this.Post_Code
      != undefined && this.dob && this.Mobile && this.Email && this.Address1 && this.Address2 && this.Address3
      && this.User_City && this.User_State && this.Post_Code != '') {
      var ngThis = this;
      var options = {
        key: "rzp_live_bdq9V4iT7npWnL",
        // key: "rzp_test_y4n4eXdJSJDeAE",
        amount: this.getdata + "00",
        currency: "INR",
        name: this.firstname,
        email: this.Email,
        description: "Calligraphy Made Easy",
        image: "/assets/images/logo/site_logo.jpg",
        "payment_capture": 1,
        handler: function (response) {
          ngThis.razorpay_payment_id = response["razorpay_payment_id"];
          console.log(ngThis.razorpay_payment_id);
          if (
            ngThis.razorpay_payment_id != undefined ||
            ngThis.razorpay_payment_id != ""
          ) {
            //console.log("test");
            ngThis.callapi();
          }

          // AFTER TRANSACTION IS COMPLETE YOU WILL GET THE RESPONSE HERE.
        },
        prefill: {
          name: "ABC", // pass customer name
          email: this.Email, // customer email
          contact: this.Mobile //customer phone no.
        },
        notes: {
          address: "address" //customer address
        },
        theme: {
          color: "#ffaa30" // screen color
        }
      };
      var rzp1 = new Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata);
        this.toast.error(response.error.reason);
      });
      rzp1.open();
      // var options = {
      //   // key: "rzp_live_bdq9V4iT7npWnL",
      //   key: "rzp_test_y4n4eXdJSJDeAE",
      //   amount: this.getdata + "00", // Example: 2000 paise = INR 20
      //   name: this.firstname,
      //   email: this.Email,
      //   description: "Calligraphy made easy",
      //   image: "assets/images/logo/site_logo.jpg", // COMPANY LOGO
      //   handler: function (response) {
      //     ngThis.razorpay_payment_id = response["razorpay_payment_id"];
      //     console.log(ngThis.razorpay_payment_id);
      //     if (
      //       ngThis.razorpay_payment_id != undefined ||
      //       ngThis.razorpay_payment_id != ""
      //     ) {
      //       //console.log("test");
      //       ngThis.callapi();
      //     }

      //     // AFTER TRANSACTION IS COMPLETE YOU WILL GET THE RESPONSE HERE.
      //   },
      //   prefill: {
      //     name: "ABC", // pass customer name
      //     email: this.Email, // customer email
      //     contact: this.Mobile //customer phone no.
      //   },
      //   notes: {
      //     address: "address" //customer address
      //   },
      //   theme: {
      //     color: "#ffaa30" // screen color
      //   }
      // };
      // console.log(options);

      // this.razorpaystore = new Razorpay(options);
      // this.razorpaystore.open();
      // api for razorpay end
    }
    else {
      alert("pls filled the form ");
    }


  }




  // api calling for post the enrollement summary
  callapi() {
    console.log(this.razorpay_payment_id);
    this.http
      .post(
        this.commonService.domain + "user_enrollment",
        {
          full: this.firstname,
          dob: this.dob,
          mobile: this.Mobile,
          email: this.Email, //

          registering_as: this.registeras, //
          parent_name: "siddhant",
          profile_picture: "assets/1.jpg",
          address_line_1: this.Address1, //
          address_line_2: this.Address2, //
          address_line_3: this.Address3, //
          user_city: this.User_City, //
          user_state: this.User_State, //
          user_country: this.User_Country, //
          post_code: this.Post_Code, //
          address_type: "Home",
          course: this.getcourse_name_show, //
          course_type: this.getcourse_centre, //
          city: this.course_city, //
          area_name: this.course_area, //
          total_fee: this.getTotalamount, //
          order_id: this.razorpay_payment_id
        },
        this.httpOptions
      )
      .subscribe(
        res => {
          console.log(res);
          console.log(res["message"]);
          // alert(res["message"]);
          this.router.navigate(["thank-you"]);
        },
        err => {
          alert(err.error.success);
          console.log(err.error.success);
        }
      );
  }
  //end api calling for post the enrollement summary
}
