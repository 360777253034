import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { StorageService } from 'src/app/service/storage.service';
import { HttpClient } from "@angular/common/http";
import { CommonService } from "../service/common.service";

declare var $: any;
declare var window: any;
window.$ = window.jQuery = $;
declare var Swiper: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {

  public num: any
  public httpOptions: any
  public subscribe_email: any
  public getstar: any;
  reviewList: any;

  constructor(
    public Route: Router,
    private http: HttpClient,
    private commonService: CommonService,
    private StorageService: StorageService) {
  }

  ngOnInit() {
    this.getallreview();
    var __slice = [].slice;
    var THIS = this;
    (function ($, window) {
      var Starrr;

      Starrr = (function () {
        Starrr.prototype.defaults = {
          rating: void 0,
          numStars: 5,
          change: function (e, value) { }
        };

        function Starrr($el, options) {
          var i, _, _ref,
            _this = this;

          this.options = $.extend({}, this.defaults, options);
          this.$el = $el;
          _ref = this.defaults;
          for (i in _ref) {
            _ = _ref[i];
            if (this.$el.data(i) != null) {
              this.options[i] = this.$el.data(i);
            }
          }
          this.createStars();
          this.syncRating();
          this.$el.on('mouseover.starrr', 'span', function (e) {
            return _this.syncRating(_this.$el.find('span').index(e.currentTarget) + 1);
          });
          this.$el.on('mouseout.starrr', function () {
            return _this.syncRating();
          });
          this.$el.on('click.starrr', 'span', function (e) {
            return _this.setRating(_this.$el.find('span').index(e.currentTarget) + 1);
          });
          this.$el.on('starrr:change', this.options.change);
        }

        Starrr.prototype.createStars = function () {
          var _i, _ref, _results;

          _results = [];
          for (_i = 1, _ref = this.options.numStars; 1 <= _ref ? _i <= _ref : _i >= _ref; 1 <= _ref ? _i++ : _i--) {
            _results.push(this.$el.append("<span class='glyphicon .glyphicon-star-empty'></span>"));
          }
          return _results;
        };

        Starrr.prototype.setRating = function (rating) {
          if (this.options.rating === rating) {
            rating = void 0;
          }
          this.options.rating = rating;
          this.syncRating();
          return this.$el.trigger('starrr:change', rating);
        };

        Starrr.prototype.syncRating = function (rating) {
          var i, _i, _j, _ref;

          rating || (rating = this.options.rating);
          if (rating) {
            for (i = _i = 0, _ref = rating - 1; 0 <= _ref ? _i <= _ref : _i >= _ref; i = 0 <= _ref ? ++_i : --_i) {
              this.$el.find('span').eq(i).removeClass('glyphicon-star-empty').addClass('glyphicon-star');
            }
          }
          if (rating && rating < 5) {
            for (i = _j = rating; rating <= 5 ? _j <= 5 : _j >= 5; i = rating <= 5 ? ++_j : --_j) {
              this.$el.find('span').eq(i).removeClass('glyphicon-star').addClass('glyphicon-star-empty');
            }
          }
          if (!rating) {
            return this.$el.find('span').removeClass('glyphicon-star').addClass('glyphicon-star-empty');
          }
        };

        return Starrr;

      })();
      return $.fn.extend({
        starrr: function () {
          var args, option;

          option = arguments[0], args = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
          return this.each(function () {
            var data;

            data = $(this).data('star-rating');
            if (!data) {
              $(this).data('star-rating', (data = new Starrr($(this), option)));
            }
            if (typeof option === 'string') {
              return data[option].apply(data, args);
            }
          });
        }
      });
    })(window.jQuery, window);

    $(function () {
      return $(".starrr").starrr();
    });

    $(document).ready(function () {
      $('#stars').on('starrr:change', function (e, value) {
        $('#count').html(value);
        THIS.getstar = value;
        console.log(THIS.getstar);
      });

      $('#stars-existing').on('starrr:change', function (e, value) {
        $('#count-existing').html(value);
      });
    });

    var swiper = new Swiper('.swiper-container3', {
      spaceBetween: 30,
      slidesPerView: 4,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }

  gotoEnroll(value) {
    this.commonService.selectedCourse = value;
    this.StorageService.updateItems({ 'selectedcheckbox': this.commonService.selectedCourse }, 'selectedcheckbox');
    if (this.commonService.selectedCourse != undefined) {
      this.Route.navigate(['/enroll-now']);
    }
  }

  checkstar(numb) {
    this.num = parseInt(numb);
    if (this.num == 1) {
      $("#star1").addClass("checked");
    }
    else if (this.num == 2) {
      $("#star1 ,#star2").addClass("checked");
    }
    else if (this.num == 3) {
      $("#star1, #star2, #star3").addClass("checked");
    }
    else if (this.num == 4) {
      $("#star1, #star2, #star3, #star4").addClass("checked");
    }
    else if (this.num == 5) {
      $("#star1, #star2, #star3, #star4, #star5").addClass("checked");
    }
  }

  newletter() {
    this.subscribe_email = (<HTMLInputElement>document.getElementById("subscribe_email")).value;
    if (this.subscribe_email != undefined && this.subscribe_email != '') {
      this.http.post(this.commonService.domain + "subscription", {
        email: this.subscribe_email,
      }, this.httpOptions).subscribe(res => {
        alert(res["success"]);
      }, err => { });
    } else {
      alert("pls enter email Id for newletter");
    }
  }

  addreview() {
    var name = $("#rname").val();
    var msg = $("#message-contact").val();
    // var countget = $("#count").val();
    var star = this.getstar;
    var data = { "name": name, "review": msg, "star": star }
    if (name != "" && msg != "" && star != undefined) {
      // this.toastr.error('Please enter Required');
      this.commonService.userreview(data).subscribe(res => {
        alert("Successful");
        window.location.reload();
      }, err => {
        // console.log(err.error);
      });
    } else {
      alert("pls filled the form");
    }

  }

  getallreview() {
    return this.commonService.getreview().subscribe(res => {
      if (res) {
        this.reviewList = res['data'];
        $(document).ready(function () {
          var swiper = new Swiper('.swiper-container4', {
            slidesPerView: 1,
            spaceBetween: 10,
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
            // init: false,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
            breakpoints: {
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }
          });
        });
      }
    });
  }
  
}

