import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-courses-grid',
  templateUrl: './courses-grid.component.html',
  styleUrls: ['./courses-grid.component.scss']
})
export class CoursesGridComponent implements OnInit {

  public course = [
    { "id": 0, "name": "Basic" },
    { "id": 1, "name": "Basic" }
  ]


  constructor(public Route: Router, private commonService: CommonService,private storageService:StorageService) { }

  ngOnInit() {
  }

/* 
  Enroll_Now($event) {
    console.log($event);
    this.Route.navigate(['/enroll-now']);
  } */

  gotoEnroll(value) {
    console.log(value);
    this.commonService.selectedCourse = value;
    this.storageService.updateItems({ 'selectedcheckbox': this.commonService.selectedCourse }, 'selectedcheckbox');
    if(this.commonService.selectedCourse!=undefined){
      this.Route.navigate(['/enroll-now']);
    }
  }
}
/* this.commonService.productList.push(value);
this.storageService.updateItems({ 'addcartData': this.commonService.productList }, 'addcartData');
this.displayCount = this.commonService.productList.length; */