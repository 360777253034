import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public selectedCourse: any;

  public domain: any = 'https://api-cme.kenmarkserver.com/api/auth/';
  public domain1: any = 'https://api-cme.kenmarkserver.com/api/';
  
  constructor(public http: HttpClient) { }

  userreview(data) {
    return this.http.post(this.domain1 + "review", data);
  }

  getreview() {
    return this.http.get(this.domain1 + "review");
  }

}
